import React from "react";
import { useSelector } from "react-redux";

export default function Instructions() {
  const { config } = useSelector((state) => state.config);
  const relativeUri = process.env.RELATIVE_URI;
  return (
    <div className="text-center">
      <b>
        {config?.instructions?.prefix} -{" "}
        <a rel="noreferrer" href={config?.instructions?.link} target="_blank">
          {config?.instructions?.linkText}
        </a>
      </b>
    </div>
  );
}
