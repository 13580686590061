import { Button, Card, CardContent, Grid, TextField, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import api from "../../config/axios.config";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function ShowCondition() {
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [defaultName, setDefaultName] = useState("");
  const [defaultCode, setDefaultCode] = useState("");
  const params = useParams();
  const [labreportRequired, setLabreportRequired] = useState(false);
  const [notifiable, setNotifiable] = useState(false);
  const [treatmentAllowed, setTreatmentAllowed] = useState(false);
  const dispatch = useDispatch();

  const conditionSchema = yup.object().shape({
    name: yup.string().required("Required").default("").max(50, "Must be less than 50 characters"),
    code: yup.string().default("").max(50, "Must be less than 50 characters").nullable(),
  });

  const formOpts = { mode: "all" };
  formOpts.resolver = yupResolver(conditionSchema);

  const {
    setValue,
    watch,
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm(formOpts);

  useEffect(() => {
    getCondition();
  }, []);

  const getCondition = () => {
    api
      .get(`/api/conditions/${params.conditionId}`)
      .then((resp) => {
        setDefaultName(resp.data?.data?.name);
        setDefaultCode(resp.data?.data?.code);
        const conditionData = resp.data?.data;
        console.log(conditionData);
        Object.keys(conditionData).forEach((key) => {
          setValue(key, conditionData[key]);
        });
        setLabreportRequired(conditionData.is_lab_report_required);
        setTreatmentAllowed(conditionData.is_treatment_allowed);
        setNotifiable(conditionData.is_notifiable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setValue("name", defaultName);
    setValue("code", defaultCode);
    setReadOnly(true);
  };

  const saveCondition = () => {
    const body = {
      id: params.conditionId,
      name: getValues().name,
      code: getValues().code,
      is_lab_report_required: labreportRequired,
      is_treatment_allowed: treatmentAllowed,
      is_notifiable: notifiable,
    };
    api
      .patch(`/api/conditions/${params.conditionId}`, body)
      .then((resp) => {
        console.log(resp);
        setDefaultName(resp.data?.condition?.name);
        setDefaultCode(resp.data?.condition?.code);
        setReadOnly(true);
        dispatch(openSnackbar({ message: resp.data.message, severity: "success" }));
      })
      .catch((err) => {
        console.log(err);
        setReadOnly(true);
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
  };

  return (
    <>
      <Card className="mt-3" variant="outlined">
        <CardContent>
          <form onSubmit={handleSubmit(saveCondition)}>
            <div className="flex justify-between">
              <p className="m-0 card-header">CONDITION INFORMATION</p>
              <div>
                {" "}
                {readOnly && (
                  <Button color="secondary" variant="contained" onClick={() => setReadOnly(false)}>
                    Edit Condition
                  </Button>
                )}
                {!readOnly && (
                  <div>
                    <Button color="secondary" variant="contained" type="submit" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <LoadingButton className="ml-3" color="secondary" variant="contained" type="submit" loading={loading}>
                      Save Condition
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
            <Grid className="pt-3" container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  className="w-100"
                  name="name"
                  inputProps={{ readOnly: readOnly }}
                  InputLabelProps={{ shrink: watch("name") !== "" }}
                  label="Condition Name *"
                  variant="standard"
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className="w-100"
                  name="code"
                  inputProps={{ readOnly: readOnly }}
                  InputLabelProps={{ shrink: watch("code") !== "" }}
                  label="Condition Code"
                  variant="standard"
                  {...register("code")}
                />
              </Grid>
            </Grid>
            <Grid className="pt-3" container spacing={2}>
              <Grid className="pt-3" item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_lab_report_required"
                      checked={labreportRequired}
                      onChange={(e) => setLabreportRequired(e.target.checked)}
                      disabled={readOnly}
                    />
                  }
                  label="Lab report required"
                />
              </Grid>
              <Grid className="pt-3" item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_treatment_allowed"
                      checked={treatmentAllowed}
                      onChange={(e) => setTreatmentAllowed(e.target.checked)}
                      disabled={readOnly}
                    />
                  }
                  label="Treatment allowed"
                />
              </Grid>
              <Grid className="pt-3" item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox name="is_notifiable" checked={notifiable} onChange={(e) => setNotifiable(e.target.checked)} disabled={readOnly} />
                  }
                  label="Notifiable"
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
