import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export const Register = ({ setOpen }) => {
  const navigate = useNavigate();
  const [registerDialog, setRegisterDialog] = useState(false);
  const dialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{
          height: "36px",
          padding: "0 16px",
          letterSpacing: ".0892857143em",
        }}
        onClick={() => {
          setRegisterDialog(true);
        }}
      >
        Register new user
      </Button>
      <Dialog open={registerDialog} onClose={dialogClose}>
      <DialogTitle id="alert-dialog-title">
            WARNING
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="warningContent" component={'div'}>
                <div>
                  The Reportable Disease Portal is restricted to Public Health Departments, Laboratories and Healthcare Facilities.
                </div><br/>
                <div>It is not intended for use by individuals not associated with the above.</div><br/>
                <div>Thank you.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(false);
              setRegisterDialog(false);
            }}
          >
            Reject
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setOpen(true);
              setRegisterDialog(false);
            }}
          >
            Accept
          </Button>
          <div style={{ flex: "1 0 0" }} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
